@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Cinzel:wght@700&family=Heebo:wght@400;500&family=Montserrat:wght@400;600&display=swap" );
@import "../node_modules/react-image-gallery/styles//css/image-gallery.css";
html{
  direction:rtl;
}

body{
  background-color:#ECECF0;
  max-width: 1920px;
  margin: auto;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.innerWrapper{
  max-width: 1365px;
  margin:0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

/* .logo-img {
  padding-top: 200px;
  margin: auto;
} */
.main_header_text{
  font-family: 'Heebo', sans-serif;
    color: #1F1546;
    font-weight: 500;

}
.main-slogan{
  font-family: 'Heebo', sans-serif;
  color: #1F1546;
  font-weight:400 ;
}
.nameOfService {
  font-family: 'Heebo', sans-serif;
  font-weight: 500;
  color: #1F1546;
  /* font-size: 50px; */
  /* margin-bottom:46px; */
  /* margin-top: 100px; */
}
.buttonText{
  font-family: 'Heebo', sans-serif;
  font-weight: 500;
  text-align: center;

}
.formBorderFields{
  border-width: 1px;
  border-color: #1F1546;
}

/* card item */
.card-item{
 /* max-width: 350px; */
  height: 270px;
  border-radius: 20px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(247,239,255,1) 22%);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  font-family: 'Berkshire Swash', cursive;
  font-family: 'Cinzel', serif;
  font-family: 'Heebo', sans-serif;
  color: #1F1546;
  font-weight: 400;
  font-size:16px;
  text-align: center;


}

.card-item-header{
  font-family: 'Montserrat', sans-serif;
  color:#07072C;
  font-weight:600;
  text-align: center;
  margin-top: 15px
}
.card-item-text{
  font-family: 'Heebo', sans-serif;
    /* font-weight: 500; */
    color: #1F1546;
    /* font-size: 16px; */

}
.smallTextAmount{

  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.start2WashText{
  font-family: 'Montserrat', sans-serif !important;


}

/* our model */
.ourModel_paragraph{
  font-family: 'Heebo', sans-serif;
    font-weight: 400;
    color: #1F1546;


}

/* vacancies */

.vacanciesText{
  font-family: 'Heebo', sans-serif;
  font-weight: 500;
  color: #1F1546;
}
/* date picker */
.react-datepicker__input-container input {
  max-width: 100px;
}


.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
/* upload button */
.btn {
  background-color: white;
  padding: 8px 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* end upload button */

